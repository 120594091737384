import request from "../../utils/request";
import getHeaders from "../../utils/headers";

const usuariosURL = '/usuario';

export function obtenerUsuarios(contexto) {
    return request(usuariosURL, {
        method: "GET",
        headers: getHeaders(contexto),
    }, contexto)
}

export function eliminarUsuario(contexto, id) {
    return request(`${usuariosURL}/${id}`, {
        method: "DELETE",
        headers: getHeaders(contexto),
    }, contexto)
}

export function obtenerRoles(contexto) {
    return request(`${usuariosURL}/roles`, {
        method: "GET",
        headers: getHeaders(contexto),
    }, contexto)
}

export function validarHash(contexto, hash) {
    return request(`${usuariosURL}/validar`, {
        method: "POST",
        body: JSON.stringify({ hash }),
        headers: getHeaders(contexto),
    }, contexto)
}

export function crearUsuario(contexto, usuario) {
    return request(`${usuariosURL}`, {
        method: "POST",
        body: JSON.stringify({ usuario }),
        headers: getHeaders(contexto),
    }, contexto)
}

export function verficarCorreo(contexto, usuario) {
    return request(`${usuariosURL}/verificar-correo`, {
        method: "POST",
        body: JSON.stringify({ usuario }),
        headers: getHeaders(contexto),
    }, contexto)
}
<template>
  <div class="contenedor">
    <CrearUsuario :drawer="drawer" @cerrarPantalla="cerrarPantallaCrear" />
    <v-alert
      type="success"
      class="alerta-exitosa"
      transition="scale-transition"
      :value="alerta"
    >
      Usuario eliminado
    </v-alert>
    <v-btn text class="btn-regresar" @click="irADashboard">
      <v-icon left> mdi-arrow-left </v-icon>
      regresar
    </v-btn>
    <div class="seccion-superior">
      <h1 class="titulo">Usuarios</h1>
      <v-btn text large rounded class="btn-crear" @click.stop="drawer = !drawer"> Crear usuario </v-btn>
    </div>
    <div class="usuarios">
      <v-card elevation="2">
        <div class="seccion-busqueda">
          <div class="autocomplete-buscar">
            <v-autocomplete
              outlined
              v-model="usuarioBusqueda"
              label="Buscar usuario"
              :search-input.sync="descripcion"
              :items="usuarios"
              item-text="nombres"
              item-value="id"
              single-line
              append-icon=""
              clearable
              return-object
              prepend-inner-icon="mdi-magnify"
              hide-details
            ></v-autocomplete>
          </div>
          <span>Resultados: {{ numeroUsuarios }} usuarios</span>
        </div>

        <v-simple-table>
          <template v-slot:default>
            <thead class="encabezado-tabla">
              <tr>
                <th class="text-left header header-nombre">Nombre</th>
                <th class="text-center header">Rol</th>
                <th class="text-center header">Correo</th>
                <th class="text-center header"></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="usuario in usuariosFiltrados" :key="usuario.id">
                <td class="columna-nombre">
                  <div class="nombre">
                    <div class="activo"></div>
                    <span>{{ usuario.nombres }}</span>
                  </div>
                </td>
                <td class="text-center">{{ usuario.rol }}</td>
                <td class="text-center">{{ usuario.email }}</td>
                <td class="columna-btn-borrar">
                  <v-btn
                    text
                    large
                    rounded
                    outlined
                    class="btn-borrar"
                    @click="mostrarModal(usuario)"
                    ><v-icon left> mdi-delete </v-icon> Borrar usuario
                  </v-btn>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card>
    </div>
    <v-dialog v-model="dialog" max-width="fit-content">
      <v-card>
        <v-card-title class="text-h5" v-if="usuarioSeleccionado">
          ¿Esta seguro de eliminar la cuenta de
          {{ usuarioSeleccionado.nombres }}?
        </v-card-title>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn text @click="dialog = false"> cancelar </v-btn>

          <v-btn class="btn-eliminar" text @click="eliminarUsuario">
            eliminar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {
  obtenerUsuarios,
  eliminarUsuario,
} from "../../services/usuarios/usuarios";
import CrearUsuario from "./CrearUsuario";
export default {
  name: "Usuarios",
  components: {
    CrearUsuario,
  },
  data() {
    return {
      drawer: false,
      alerta: false,
      usuarioBusqueda: null,
      usuarioSeleccionado: null,
      descripcion: null,
      dialog: false,
      numeroUsuarios: 0,
      usuariosFiltrados: [],
      usuarios: [],
    };
  },
  watch: {
    usuarioBusqueda(value) {
      if (value) {
        this.usuariosFiltrados = [this.usuarios.find((r) => r.id === value.id)];
      } else {
        this.usuariosFiltrados = this.usuarios;
      }
    },
  },
  methods: {
    cerrarPantallaCrear(valor){
      this.drawer = valor;
    },
    irADashboard() {
      this.$router.push("dashboard");
    },
    obtenerUsuarios() {
      obtenerUsuarios(this).then((resp) => {
        this.numeroUsuarios = resp.length;
        this.usuarios = resp;
        this.usuariosFiltrados = resp;
      });
    },
    mostrarModal(usuario) {
      this.usuarioSeleccionado = usuario;
      this.dialog = true;
    },
    eliminarUsuario() {
      eliminarUsuario(this, this.usuarioSeleccionado.id).then(() => {
        this.dialog = false;
        this.alerta = true;
        this.usuarios.splice(
          this.usuarios.findIndex((r) => r.id === this.usuarioSeleccionado.id),
          1
        );
        this.numeroUsuarios = this.usuarios.length;
        this.usuariosFiltrados = this.usuarios;
        this.descripcion,
          this.usuarioBusqueda,
          (this.usuarioSeleccionado = null);
        setTimeout(() => {
          this.alerta = false;
        }, 2500);
      });
    },
  },
  created() {
    this.obtenerUsuarios();
  },
};
</script>

<style scoped>
.activo {
  width: 12px;
  height: 12px;
  background: #3ecf8e;
  border-radius: 50%;
}

.alerta-exitosa {
  width: fit-content;
  align-self: end;
}

.inactivo {
  width: 12px;
  height: 12px;
  background: #c4c4c4;
  border-radius: 50%;
}

.autocomplete-buscar {
  width: 350px;
}

.btn-borrar {
  width: 175px;
  height: 35px !important;
  text-transform: capitalize;
  border-color: #842e7c !important;
  color: #842e7c;
}

.btn-crear {
  text-transform: none;
  background: #842e7c;
  width: 181px;
  color: white;
}

.btn-eliminar {
  color: #842e7c;
}

.btn-regresar {
  color: white;
  width: fit-content;
}

.contenedor-crear {
  position: absolute;
}

.columna-btn-borrar {
  text-align: end;
  padding-right: 2em !important;
}

.columna-nombre {
  padding: 1.5em 2.2em !important;
  font-weight: 600;
}

.header {
  text-transform: uppercase;
  font-size: 14px;
}

.header-nombre {
  padding-left: 5em !important;
}

.contenedor {
  margin-top: 5em;
  display: flex;
  flex-direction: column;
}

.encabezado-tabla {
  background: #f2f2f2;
}

.nombre {
  display: flex;
  gap: 1em;
  align-items: center;
}

.titulo {
  font-weight: 600;
  color: white;
}

.seccion-busqueda {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.5em 2em;
}

.seccion-superior {
  margin-top: 2em;
  display: flex;
  justify-content: space-between;
}

.usuarios {
  margin-top: 1.5em;
}
</style>